<template>

	<div class="blog-component mini-spacer bg-extra-light">
		<v-container>
			<v-row justify="center">
				<v-col cols="12" sm="10" md="9" lg="7">
					<div class="text-center">
						<h2 class="section-title font-weight-medium">Our Best Sellers</h2>
						<p class="text-muted">
							Explore Palawan's hidden wonders with our best-selling private tours and transportation
							services.
						</p>
					</div>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="4" sm="6" v-for="item in bestsellers" :key="item.code">
					<listing-item-card :item="item" />
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script setup>
const listingStore = useListingsStore();
const bestsellers = toRef(() => listingStore.getBestsellers);

</script>
